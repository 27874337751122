import React from "react";

const services = [
  {
    title: "Gyermekvállalás, hozzátáplálás, egészségtudatos nevelés",
  },
  {
    title: "Prevenciós előadás",
  },
  {
    title: "Étlaptervezés cégek, intézmények számára",
  },
  {
    title: "Gesztációs diabétesz (GDM)",
  },
  {
    title: "Inzulinrezisztencia",
  },
  {
    title: "Policisztás ovárium szindróma (PCOS)",
  },
  {
    title: "Kettes típusú cukorbetegség",
  },
  {
    title: "Pajzsmirigy betegségek",
  },
  {
    title: "Köszvény, emelkedett húgysav szint",
  },
  {
    title: "Tejcukorérzékenység (laktózintolerancia)",
  },
  {
    title: "Tejfehérje allergia",
  },
  {
    title: "Refluxbetegség (GERD)",
  },
  {
    title: "Epeutak megbetegedései",
  },
  {
    title: "Székrekedés",
  },
  {
    title: "Diverticulosis, diverticulitis",
  },
  {
    title: "Emelkedett vérzsír értékek",
  },
  {
    title: "Magas vérnyomás (hipertónia)",
  },
];

export default function ServicesPage() {
  return (
    <div id="services-page">
      <p className="h1 my-5 text-center">Szolgáltatások</p>
      <p className="h3">Miben tud segíteni egy dietetikus?</p>
      <p>
        A dietetikus olyan felsőfokú végzettségű, diplomás szakember, aki egészséges és betegségben szenvedő emberek számára egyaránt segíteni tud a számukra legmegfelelőbb étrend
        kialakításában.
      </p>
      <p className="h3">Miben NEM tud segíteni egy dietetikus?</p>
      <p>A dietetikus nem orvos, nem diagnosztizál betegségeket és nem ad receptet/beutalót.</p>
      <p className="h3">Milyen esetben fordulhat hozzám?</p>
      <p>
        Az alábbiakban összegyűjtöttem néhány betegséget és témakört melyben dietetikusként segíteni tudok Önnek. Ha esetleg más témában lenne szüksége tanácsadásra, kérem akkor is
        keressen bizalommal, és megbeszéljük, hogy tudok-e segíteni.
      </p>
      <ul>
        {services
          .sort((s1, s2) => s1.title.localeCompare(s2.title))
          .map((s) => (
            <li>{s.title}</li>
          ))}
      </ul>
    </div>
  );
}
