import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./PricesPage.css";

const prices = [
  {
    title: "Egyéni Tanácsadás",
    price: "Első alkalom: 20,000 Ft | Kontroll: 13,000 Ft",
    body: (
      <>
        <p>Időtartam: első alkalom 60-90 perc, kontroll 45-60 perc</p>
        <p>Személyesen vagy online (online tanácsadás esetén a számla előre fizetendő)</p>
        <p>Tartalma:</p>
        <ul>
          <li>Az előre elküldött anamnézis lap elemzése, átbeszélése (ha nincs előre elküldve, akkor az anamnézis felvétele is a tanácsadási időből megy el)</li>
          <li>Az előre elküldött táplálkozási napló elemzése, átbeszélése (ha nincs előre elküldve, akkor az elemzés is a tanácsadási időből megy el)</li>
          <li>Antropometriai vizsgálat (magasság, testsúly, testösszetétel és derékkörfogat mérés)</li>
          <li>Táplálkozási tanácsadás</li>
          <li>Tanácsadói anyag, amely minden fontos információt tartalmaz (elektronikus formában)</li>
          <li>7 napos email konzultációs lehetőség a tanácsadás után</li>
        </ul>
        <p>Kontroll időpont az első tanácsadást követő 12 hónapon belül érhető el, azonos betegséggel/témaval kapcsolatban.</p>
      </>
    ),
  },
  {
    title: "Egyéni Mintaétrend Készítés",
    price: "20,000 Ft",
    body: (
      <>
        <p>Mikor ajánlott?</p>
        <p>Tanácsadást követően, ha nincs kérdése, de szeretne még több receptet.</p>
        <p>Tartalma:</p>
        <ul>
          <li>7 napos, személyre szabott mintaétrend (receptekkel és elkészítési javaslatokkal, elektronikus formában)</li>
          <li>Egyéni igények, kedvelt - nem kedvelt ételek, figyelembe vételével</li>
          <li>A Te életritmusodhoz igazítva</li>
        </ul>
      </>
    ),
  },
  {
    title: (
      <>
        <span style={{ color: "red" }}>KEDVEZMÉNYES</span> Egyéni Csomag (tanácsadás + mintaétrend)
      </>
    ),
    price: (
      <>
        <s>40,000 Ft</s> 30,000 Ft
      </>
    ),
    body: (
      <>
        <p>Időtartam: első alkalom 60-90 perc, kontroll 45-60 perc</p>
        <p>Személyesen vagy online (online tanácsadás esetén a számla előre fizetendő)</p>
        <p>Tartalma:</p>
        <ul>
          <li>Az előre elküldött anamnézis lap elemzése, átbeszélése (ha nincs előre elküldve, akkor az anamnézis felvétele is a tanácsadási időből megy el)</li>
          <li>Az előre elküldött táplálkozási napló elemzése, átbeszélése (ha nincs előre elküldve, akkor az elemzés is a tanácsadási időből megy el)</li>
          <li>Antropometriai vizsgálat (magasság, testsúly, testösszetétel és derékkörfogat mérés)</li>
          <li>Táplálkozási tanácsadás</li>
          <li>Tanácsadói anyag, amely minden fontos információt tartalmaz (elektronikus formában)</li>
          <li>7 napos email konzultációs lehetőség a tanácsadás után</li>
          <li>7 napos, személyre szabott mintaétrend (receptekkel és elkészítési javaslatokkal, elektronikus formában)</li>
        </ul>
      </>
    ),
  },
  {
    title: (
      <>
        <span style={{ color: "red" }}>KEDVEZMÉNYES</span> Családi Csomag (tanácsadás + mintaétrend)
      </>
    ),
    price: (
      <>
        <s>60,000 Ft</s> 45,000 Ft
      </>
    ),
    body: (
      <>
        <p>Időtartam: 60-90 perc</p>
        <p>Kizárólag személyesen</p>
        <p>Mikor ajánlott?</p>
        <p>
          Ha az egész családot érinti egy téma, vagy több gyermek van és szeretné egészségtudatos táplálkozásra nevelni őket, de korból, nemből, mozgásformából adódóan mások az
          igényeik, akkor mindenkinek személyre szabom az étrendjét. Közös háztartásban élő közeli hozzátartozók számára érhető el, azonos betegséggel/témaval kapcsolatban.
        </p>
        <p>Tartalma:</p>
        <ul>
          <li>Az előre elküldött anamnézis lap elemzése, átbeszélése (ha nincs előre elküldve, akkor az anamnézis felvétele is a tanácsadási időből megy el)</li>
          <li>Az előre elküldött táplálkozási napló elemzése, átbeszélése (ha nincs előre elküldve, akkor az elemzés is a tanácsadási időből megy el)</li>
          <li>Antropometriai vizsgálat (magasság, testsúly, testösszetétel és derékkörfogat mérés)</li>
          <li>Táplálkozási tanácsadás</li>
          <li>Tanácsadói anyag, amely minden fontos információt tartalmaz (elektronikus formában)</li>
          <li>7 napos email konzultációs lehetőség a tanácsadás után</li>
          <li>7 napos, személyre szabott mintaétrend (receptekkel és elkészítési javaslatokkal, elektronikus formában)</li>
        </ul>
      </>
    ),
  },
  {
    title: "GDM Mentoráló Csomag",
    price: "15,000 Ft",
    body: (
      <>
        <p>Időtartam: heti 1 alkalom 4 héten át</p>
        <p>Kizárólag online (pl. email, chat)</p>
        <p>Mikor ajánlott?</p>
        <p>Terhességi cukorbetegség esetén</p>
        <p>Tartalma:</p>
        <ul>
          <li>Vércukor napló elemzés, étrendi napló elemzés, konzultáció</li>
        </ul>
      </>
    ),
  },
  {
    title: "Étlaptervezés",
    price: "Egyéni ár",
    body: (
      <>
        <p>Cégek, intézmények számára egyéni megbeszélés szerint.</p>
      </>
    ),
  },
  {
    title: "Prevenciós Előadás",
    price: "Egyéni ár",
    body: (
      <>
        <p>Iskolákban, óvodákban, munkahelyeken egyéni megbeszélés szerint.</p>
      </>
    ),
  },
];

function PriceAccordionItem({ price }) {
  return (
    <>
      <Accordion.Item eventKey={price.title}>
        <Accordion.Header>
          <Container>
            <Row className="fw-bold">
              <Col>{price.title}</Col>
              <Col md="auto">{price.price}</Col>
            </Row>
          </Container>
        </Accordion.Header>
        <Accordion.Body>{price.body}</Accordion.Body>
      </Accordion.Item>
    </>
  );
}

function PriceAccordion({ prices }) {
  return (
    <>
      <Accordion alwaysOpen>
        {prices.map((p, i) => (
          <PriceAccordionItem key={i} price={p}></PriceAccordionItem>
        ))}
      </Accordion>
    </>
  );
}

export default function PricesPage() {
  return (
    <div id="prices-page">
      <p className="h1 mt-5 text-center">Árak</p>
      <p className="i mb-5 text-center">(2025.03.01-től)</p>
      <PriceAccordion prices={prices}></PriceAccordion>
    </div>
  );
}
